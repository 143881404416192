import { render, staticRenderFns } from "./AccordionList.vue?vue&type=template&id=33beb6c9&scoped=true&"
import script from "./AccordionList.vue?vue&type=script&lang=js&"
export * from "./AccordionList.vue?vue&type=script&lang=js&"
import style0 from "./AccordionList.vue?vue&type=style&index=0&id=33beb6c9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33beb6c9",
  null
  
)

export default component.exports